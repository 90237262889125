<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="4">
                <v-select
                  :reduce="(option) => option.value"
                  :items="SearchIdOptions"
                  :rules="SearchIdRules"
                  v-model="SearchId"
                  label="Search Type"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" v-if="SearchId == 2">
                <v-text-field
                  v-model="MembershipId"
                  :rules="MembershipIdRules"
                  label="MembershipId"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="SearchId == 1">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="FromZoneCodeOptionsLoading"
                  :items="FromZoneCodeOptions"
                  :rules="FromZoneCodeRules"
                  v-model="FromZoneCode"
                  label="From Zone"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" v-if="SearchId == 1">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="FromLomCodeOptions"
                  :loading="FromLomCodeOptionsLoading"
                  :rules="FromLomCodeRules"
                  v-model="FromLomCode"
                  label="From LOM"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>
                Members of {{ lom.lom_name }} LOM in
                {{ zone.zone_display_text }}
              </h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="-1"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="true"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}
                  </v-chip>
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
        <v-container v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-danger">
                Total members selected : {{ selected.length }}
              </h3>
            </v-col>
          </v-row>
          <p></p>
          <v-form ref="form3" v-model="valid2" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="2">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="YearIdOptionsLoading"
                  :items="YearIdOptions"
                  :rules="YearIdRules"
                  v-model="YearId"
                  label="Year"
                  required
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ToZoneCodeOptionsLoading"
                  :items="ToZoneCodeOptions"
                  :rules="ToZoneCodeRules"
                  v-model="ToZoneCode"
                  label="To Zone"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ToLomCodeOptions"
                  :loading="ToLomCodeOptionsLoading"
                  :rules="ToLomCodeRules"
                  v-model="ToLomCode"
                  label="To LOM"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <!-- 
              <v-col cols="12" md="3">
                <v-select
                  :reduce="(option) => option.value"
                  :items="YearHalfIdOptions"
                  :rules="YearHalfIdRules"
                  v-model="YearHalfId"
                  label="Year Half"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row wrap> -->
              <v-col align="center" cols="12" md="5">
                <v-text-field
                  v-model="ApprovalDescription"
                  :rules="ApprovalDescriptionRules"
                  label="Approval Description"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid2"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  color="#1db954"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Approve Transfer
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",

      zone: {},
      lom: {},

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      FromZoneCodeRules: [(v) => !!v || "Zone is required"],
      FromZoneCode: "",
      FromZoneCodeOptions: [],
      FromZoneCodeOptionsLoading: false,

      FromLomCodeRules: [(v) => !!v || "LOM is required"],
      FromLomCode: "",
      FromLomCodeOptions: [],
      FromLomCodeOptionsLoading: false,

      ToZoneCodeRules: [(v) => !!v || "Zone is required"],
      ToZoneCode: "",
      ToZoneCodeOptions: [],
      ToZoneCodeOptionsLoading: false,

      ToLomCodeRules: [(v) => !!v || "LOM is required"],
      ToLomCode: "",
      ToLomCodeOptions: [],
      ToLomCodeOptionsLoading: false,

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By LOM" },
        { value: 2, text: "By Membership Id" },
      ],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      YearHalfId: "",
      YearHalfIdRules: [(v) => !!v || "Year Half is required"],
      YearHalfIdOptions: [
        { value: "", text: "--Select Half--" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    FromZoneCodeOptions: function () {
      console.log("watch FromZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.FromZoneCodeOptionsLoading = false;
    },
    FromZoneCode: function () {
      console.log("watch FromZoneCode");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.FromLomCodeOptionsLoading = true;
      this.getLomCodeOptions(
        "FromLomCode",
        "FromLomCodeOptions",
        this.FromZoneCode
      );
    },
    FromLomCodeOptions: function () {
      console.log("watch FromLomCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.FromLomCodeOptionsLoading = false;
    },
    ToZoneCodeOptions: function () {
      console.log("watch ToZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToZoneCodeOptionsLoading = false;
    },
    ToZoneCode: function () {
      console.log("watch ToZoneCode");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = true;
      this.getLomCodeOptions("ToLomCode", "ToLomCodeOptions", this.ToZoneCode);
    },
    ToLomCodeOptions: function () {
      console.log("watch ToLomCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ToLomCodeOptionsLoading = false;
    },
    YearIdOptions: function () {
      console.log("watch YearIdOptions");
      this.LoadingFlag = false;
      this.YearIdOptionsLoading = false;
    },
    YearId: function () {
      console.log("watch YearId");
      this.ToZoneCode = "";
      this.ToLomCode = "";
      this.ToLomCodeOptions = [];
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "members_transfer",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.FromZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("FromZoneCode", "FromZoneCodeOptions");
      this.ToZoneCodeOptionsLoading = true;
      this.getZoneCodeOptions("ToZoneCode", "ToZoneCodeOptions");
      this.YearIdOptionsLoading = true;
      this.getYearCodeOptions("YearId", "YearIdOptions");
    },
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getZoneCodeOptions(source, destination) {
      console.log("getZoneCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      var CurrentYearId = this.CurrentYearId;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: CurrentYearId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions(source, destination, ZoneCode) {
      console.log("getLomCodeOptions called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", ZoneCode=" +
          ZoneCode
      );
      var CurrentYearId = this.CurrentYearId;
      if (ZoneCode != "" && CurrentYearId != "") {
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: CurrentYearId,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Zone should not be empty";
        this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = 1;
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination +
          ", CurrentYearId=" +
          CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.selected = [];
        this.rows = [];
        this.zone = {};
        this.lom = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members";
        var upload = {
          UserInterface: UserInterface,
          additional: {
            MembershipId: this.MembershipId,
            LomCode: this.FromZoneCode,
            LomCode: this.FromLomCode,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records;
              thisIns.zone = response.data.zone;
              thisIns.lom = response.data.lom;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      var validate2 = this.$refs.form3.validate();
      console.log("validate1=" + validate1 + "validate2=" + validate2);

      var members = this.selected;
      var n1 = members.length;
      console.log("n1=" + n1 + ", members=" + JSON.stringify(members));

      // this.rows = [];

      if (validate1 && validate2 && n1 > 0) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/transfer";
        var upload = {
          UserInterface: this.SearchId,
          FromZoneCode: this.FromZoneCode,
          FromLomCode: this.FromLomCode,
          MembershipId: this.MembershipId,
          ToZoneCode: this.ToZoneCode,
          ToLomCode: this.ToLomCode,
          YearId: this.YearId,
          YearHalf: this.YearHalfId,
          ApprovalDescription: this.ApprovalDescription,
          Members: members,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1 || !validate2) {
          message += "Kindly fill the required fields";
        }
        if (n1 == 0) {
          message += "Atleast one member is required to transfer";
        }
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.tableColumns1.push(
      { text: "Photo", value: "ProfilePic" },
      { text: "Membership Id", value: "MembershipId" },
      { text: "Member Name", value: "MemberName" },
      { text: "Recent Membership", value: "RecentDuesTxt" },
      { text: "Mobile No", value: "MobileNo" },
      { text: "Email", value: "EmailId" },
      { text: "Address", value: "AddressTxt" },
      { text: "Actions", value: "actions" }
    );
  },
};
</script>